// import React from 'react';
import {ReactComponent as Dark} from '../images/aws_dark.svg';
import {ReactComponent as Light} from '../images/aws_light.svg';

export default function AWS_LOGO({width, height}) {
  // const theme = {mode: 'dark'};
  return <Dark width={20} height={20}/>;
  // return theme.mode === 'dark' ? (
  //   <Light width={width} height={height} />
  // ) : (
  //   <Dark width={width} height={height} />
  // );
}
