import React from "react";
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import { useSelector } from "react-redux";







export default function Settings() {
  const userData = useSelector((state) => ({
    id: state.userDetailsReducer.id,
  }));
  const user = useSelector((state) => ({
    email: state.userDetailsReducer.email,
  }));
  const userName = useSelector((state) => ({
    name: state.userDetailsReducer. name,
  }));
  
  return ( <Card>
    <Card.Header> <b>Settings</b></Card.Header>
    <Card.Body>
    <p> Name: &nbsp; 
      {userName.name}</p>
      <p> Email: &nbsp;  
      {user.email}</p>
       <p> CustomerID: &nbsp; 
      {userData.id}</p>
      
      
    </Card.Body>
   
  </Card>
  )
}
