import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    button {
      background-color: skyblue;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      font-size: 20px;
      border-radius: 4px;
      font-weight: bold;
      border-color: skyblue;
    }
    div {
      display: flex;
      align-items: center;
      h2 {
        font-size: 30px;
      }
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
    }
  }
`;
