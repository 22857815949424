import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ADB2C_TOKENS, SIGNIN_SIGNUP } from "./store/actions/actions";

import axios from "axios";
import * as msal from "msal";

const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_ADB2C_SIGNIN_POLICY,
    forgotPassword: process.env.REACT_APP_ADB2C_FORGOT_PASSWORD_POLICY,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_ADB2C_SIGNIN_AUTHORITY,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_ADB2C_FORGOT_PASSWORD_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_ADB2C_DOMAIN_AUTHORITY,
};
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID, //"2fdd06f3-7b34-49a3-a78b-0cf1dd87878e", // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.REACT_APP_REDIRECT_URL, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};
const loginRequest = {
  scopes: ["openid", "profile"],
};
const msalApp = new msal.UserAgentApplication(msalConfig);

const UserAuth = () => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const loginTypes = {
    popup: "popup",
    redirect: "redirect",
  };

  async function userDataApi(id_token) {
    setLoading(true);
    try {
      await axios
        .get(
          process.env.REACT_APP_SIGNUP_SIGNIN +
            `signupOrSignin?id_token=${id_token}`,
          {
            headers: {
              "Ocp-Apim-Subscription-Key":
                process.env.REACT_APP_SUBSCRIPTION_KEY,
            },
          }
        )
        .then((res) => res.data)
        .then((response) => {
          dispatch({
            type: SIGNIN_SIGNUP,
            payload: response,
          });

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const handleUser = async () => {
    msalApp.handleRedirectCallback((error, response) => {
      if (error) {
        handleLoginErrorAsync(error);
      } else {
        handleLoginSuccess(response);
      }
    });
  };

  // function handlePolicyChange(response) {
  //   /**
  //    * We need to reject id tokens that were not issued with the default sign-in policy.
  //    * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
  //    * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
  //    */
  //   console.log("good good == ", JSON.stringify(response));

  //   if (response.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
  //     window.alert(
  //       "Password has been reset successfully. \nPlease sign-in with your new password."
  //     );
  //     msalApp.logout();
  //   }
  // }

  // function handleUserCancel(response) {
  //   if (response.errorCode === "user_cancelled") {
  //     window.alert("User cancelled the flow.");
  //   } else if (response.errorCode === "access_denied") {
  //     window.alert(
  //       "The user has cancelled entering self-asserted information."
  //     );
  //   } else {
  //     handleLoginErrorAsync(response);
  //   }
  // }

  useEffect(() => {
    handleUser();
  }, []);

  const handleLoginErrorAsync = async (response) => {
    try {
      if (response.errorMessage.indexOf("AADB2C90118") > -1) {
        await msalApp.loginRedirect(b2cPolicies.authorities.forgotPassword);
      }
    } catch (error) {
      console.log("error == /////  ", JSON.stringify(error));
    }
  };

  const handleLoginSuccess = (loginResponse) => {
    if (
      loginResponse != null &&
      loginResponse.tokenType === "id_token" &&
      loginResponse.idToken?.issuer === process.env.REACT_APP_ADB2C_ISSUER &&
      loginResponse.idToken?.claims?.aud ===
        process.env.REACT_APP_ADB2C_CLIENT_ID &&
      loginResponse.idToken?.claims?.tfp ===
        process.env.REACT_APP_ADB2C_SIGNIN_POLICY
    ) {
      let incomingAccount = msalApp.getAccount();
      setAccount(incomingAccount);
    } else {
      msalApp.logout();
    }
  };

  const loginAsync = async () => {
    try {
      let response = await msalApp
        .loginPopup(loginRequest)
        .catch(async (error) => {
          await handleLoginErrorAsync(error);
        });

      const {
        idToken: { rawIdToken },
      } = response;
      userDataApi(rawIdToken);
      dispatch({
        type: ADB2C_TOKENS,
        payload: rawIdToken,
      });
      handleLoginSuccess(response);
    } catch (er) {
      console.log("error == ", er);
    }
  };

  const logoutAsync = async () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("signed");
    dispatch({
      type: "SIGN_OUT",
    });

    msalApp.logout();
  };

  const getAccessTokenAsync = async () => {
    let request = loginRequest;
    request.scopes = ["openid"];
    let response = await msalApp
      .acquireTokenSilent(request)
      .catch(async (error) => {
        console.log("login Error ", error);
      });

    return response.accessToken;
  };

  return {
    loading,
    account,
    loginAsync,
    logoutAsync,
    getAccessTokenAsync,
  };
};

export default UserAuth;
