import React, { useState } from "react";
import AWS_LOGO from "../../../images/AWS_LOGO";
import ConnectionHeader from "../../../components/ConnectionHeader";
import axios from "../../../connection/axios";
import LeftArrow from '../../../images/left-arrow.png';
import {
  Button,
  Form, InputGroup
} from "react-bootstrap";
import { useSelector } from "react-redux";
import {BrowserRouter} from "react-router-dom";
import history from "../../../services/history";
import RoutesList from "../../../routes/RoutesList";
import ClipboardJS from "clipboard/dist/clipboard";
const {
  REACT_APP_AWS_ACCOUNT_INSTRUCTIONS,
  REACT_APP_AWS_CONNECTION,
  REACT_APP_AWS_CLOUD_FORMATION,
} = process.env;

export default function ConnectAWSAccount(props) {
  const [isArnGenerated, setIsArnGenerated] = useState(false);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [cloudFormationLoading, setCloudFormationLoading] = useState(false);
  const [s3URL, setS3URL] = useState("");
  const [connectionDialog, toggleConnectionDialog] = useState(!false);

  const [s3BucketName, setS3BucketName] = useState("");
  const [s3bucketCostreportName, setS3bucketCostreportName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [roleArn, setRoleArn] = useState("");

  const [s3BucketError, setS3BucketError] = useState({
    status: false,
    msg: "",
  });
  const [reportNameError, setReportNameError] = useState({
    status: false,
    msg: "",
  });
  const [roleARNError, setRoleARNError] = useState({ status: false, msg: "" });
  const [displayNameError, setDisplayNameError] = useState({
    status: false,
    msg: "",
  });

  const { customerId } = useSelector((state) => ({
    customerId: state.userDetailsReducer.id,
  }));

  function onConnectionSuccess() {
    props.onConnectionSuccess();
    // history.push(RoutesList.AWS_LIST);
    // new BrowserRouter().props.forceRefresh();
  }

  function validateFields() {
    var noErrors = true;
    if (!s3BucketName.trim()) {
      setS3BucketError({
        status: true,
        msg: "Please enter the S3 bucket name",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.\-_]{1,255}$/.test(s3BucketName.trim())) {
      setS3BucketError({
        status: true,
        msg: "Please enter the valid S3 bucket name",
      });
      noErrors = false;
    }

    if (!s3bucketCostreportName.trim()) {
      setReportNameError({ status: true, msg: "Please enter the Report Name" });
      noErrors = false;
    } else if (
        !/^[a-zA-Z0-9.\-_]{1,255}$/.test(s3bucketCostreportName.trim())
    ) {
      setReportNameError({
        status: true,
        msg: "Please enter the valid Report Name",
      });
      noErrors = false;
    }

    if (!displayName.trim()) {
      setDisplayNameError({
        status: true,
        msg: "Please enter Connection Display Name",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.\-_\s]{3,25}$/.test(displayName.trim())) {
      setDisplayNameError({
        status: true,
        msg: "Please enter the valid Connection Display Name",
      });
      noErrors = false;
    }

    if (!roleArn.trim()) {
      setRoleARNError({ status: true, msg: "Please enter Role ARN" });
      noErrors = false;
    } else if (
        !/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(roleArn.trim())
    ) {
      setRoleARNError({ status: true, msg: "Please enter the valid Role ARN" });
      noErrors = false;
    }
    return noErrors;
  }

  async function onConnect(e) {
    e.preventDefault();
    if (validateFields()) {
      setConnectionLoading(true);
      await axios
          .post(REACT_APP_AWS_CONNECTION, {
            costUsageReports: {
              s3bucketCostreportName: s3bucketCostreportName.trim(),
              s3bucketName: s3BucketName.trim(),
            },
            customerId,
            displayName: displayName.trim(),
            roleArn: roleArn.trim(),
          })
          .then((response) => response.data)
          .then((response) => {
            if ("statusCode" in response) {
              switch (response.statusCode.toString()) {
                case "200":
                {
                  alert(response.successMessage);
                  onConnectionSuccess();
                }
                  break;
                case "3001":
                {
                  alert("Null CustomerID");
                }
                  break;
                case "3005":
                {
                  alert("Account already exists");
                }
                  break;
                case "3048":
                {
                  alert("Role arn is invalid");
                  onConnectionSuccess();
                }
                  break;
                case "3049":
                {
                  alert("Role arn is invalid");
                  onConnectionSuccess();
                }
                  break;
                case "3050":
                {
                  alert("s3bucketName is invalid");
                  onConnectionSuccess();
                }
                  break;
                case "3051":
                {
                  alert("S3bucket Costreport Name is invalid");
                  onConnectionSuccess();
                }
                  break;
                case "3045":
                {
                  alert("S3bucket Costreport Name is invalid");
                  onConnectionSuccess();
                }
                  break;
                default: {
                  alert(
                      "Please contact your administrator or our support team, support@synapsesai.com"
                  );
                }
              }
            } else {
              alert(
                  "Please contact your administrator or our support team, support@synapsesai.com"
              );
            }
          })
          .catch(console.log)
          .finally(() => setConnectionLoading(false));
    }
    e.preventDefault();
  }

  async function onCloudTemplateGenerate(e) {
    e.preventDefault();
    const bucketName = s3BucketName.trim();
    var noErrors = true;
    if (bucketName == "") {
      setS3BucketError({
        status: true,
        msg: "Please enter the S3 bucket name",
      });
      noErrors = false;
    } else if (/^[a-zA-Z0-9.\-_]{1,255}$/.test(bucketName) == false) {
      setS3BucketError({
        status: true,
        msg: "Please enter the valid S3 bucket name",
      });
      noErrors = false;
    }
    if (!s3bucketCostreportName.trim()) {
      setReportNameError({ status: true, msg: "Please enter the Report Name" });
      noErrors = false;
    } else if (
        !/^[a-zA-Z0-9.\-_]{1,255}$/.test(s3bucketCostreportName.trim())
    ) {
      setReportNameError({
        status: true,
        msg: "Please enter the valid Report Name",
      });
      noErrors = false;
    }

    if (noErrors) {
      setCloudFormationLoading(true);
      await axios
          .get(REACT_APP_AWS_CLOUD_FORMATION, {
            params: {
              customerId,
              s3BucketName: bucketName,
            },
          })
          .then((response) => response.data)
          .then((response) => response.data)
          .then((response) => {
            if (response) {
              setIsArnGenerated(true);
              setS3URL(response.s3URL);
            } else {
              alert("Something went wrong");
            }
          })
          .finally(() => setCloudFormationLoading(false));
    }
    e.preventDefault();
  }

  function onCopyToClipboard(){
    var input = document.querySelector('#s3URLField');
    input.select();
    document.execCommand('copy');
  }

  return (
      <div style={{ padding: 10 }}>
        <div style={{display: 'flex', flexDirection:'row', alignItems:'center', marginBottom: 10}}>
          <img onClick={props.onBack} src={LeftArrow} height={20} style={{cursor: "pointer",marginRight: 10}}/><span>Account List</span>
        </div>
        <ConnectionHeader
            buttonHidden={true}
            Icon={AWS_LOGO}
        />
          <div
              style={{
                borderRadius: 5,
                marginTop: 10,
                marginBottom: 10,
                padding: 20,
                borderWidth: 2,
                borderColor: "#E8E8E8",
                borderStyle: "solid",
                backgroundColor: "#FFF",
              }}
          >
            <div
                style={{
                  // display: "flex",
                  // flexDirection: "row",
                  // justifyContent: "space-evenly",
                  // marginBottom: 10,
                }}
            >
              <div style={{
                // display: "flex", flex: 1, marginRight: 10
              }}>
                <Form style={{ flex: 1, marginBottom: 10 }}>
                  <TextField
                      label={"S3 Bucket Name *"}
                      hasError={s3BucketError.status}
                      errorMessage={s3BucketError.msg}
                      onInput={(v)=> {
                        setIsArnGenerated(false);
                        setS3BucketName(v);
                      }}
                      placeholder={"Enter S3 Bucket Name"}
                  />
                  <TextField
                      label={"Report Name *"}
                      hasError={reportNameError.status}
                      errorMessage={reportNameError.msg}
                      onInput={(v)=> {
                        setIsArnGenerated(false);
                        setS3bucketCostreportName(v);
                      }}
                      placeholder={"Enter Report Name"}
                  />
                  <Button
                      onClick={onCloudTemplateGenerate}
                      variant="primary"
                      type="submit"
                  >
                    Generate Cloudformation Template
                  </Button>
                </Form>
                {s3URL ? (
                    <div style={{ marginTop: 5, marginBottom: 5 }}>
                      <Form.Label
                          style={{ marginRight: 10, fontFamily: "Poppins_SemiBold" }}
                      >
                        Amazon S3 URL for Cloudformation Template{" "}(Copy this URL)
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control value={s3URL} id={'s3URLField'}
                                      placeholder="AWS S3 URL"
                                      aria-label=""
                                      aria-describedby="basic-addon1"
                        />
                        <InputGroup.Append>
                          <Button variant="outline-secondary" onClick={()=>{
                            new ClipboardJS('#s3URLField');
                            onCopyToClipboard()
                          }}>Copy</Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                ) : null}
              </div>
              <div style={{
                // display: "flex", flex: 1, marginLeft: 10
              }}>
                <Form style={{ flex: 1, marginBottom: 10}}>
                  <TextField
                      disabled={!isArnGenerated}
                      label={"Role ARN *"}
                      hasError={roleARNError.status}
                      errorMessage={roleARNError.msg}
                      onInput={setRoleArn}
                      placeholder={"Enter Role ARN"}
                  />
                  <TextField
                      disabled={!isArnGenerated}
                      label={"Connection Display Name *"}
                      hasError={displayNameError.status}
                      errorMessage={displayNameError.msg}
                      onInput={setDisplayName}
                      placeholder={"Enter Connection Display Name"}
                  />
                  <Button disabled={!isArnGenerated} onClick={onConnect}
                          variant={isArnGenerated?"primary":"dark"}
                          type="submit">
                    Connect
                  </Button>
                </Form>
              </div>
            </div>
            <span>
            <a
                href={REACT_APP_AWS_ACCOUNT_INSTRUCTIONS}
                target={"_blank"}
                style={{ color: "#14aeff" }}
            >
              View Instructions
            </a>{" "}
              to create role using this cloud formation template in your AWS
            account
          </span>
          </div>
      </div>
  );
}

// const Toast = {
//     LONG: 4000,
//     SHORT: 2000,
//     HIGH: 'danger',
//     MEDIUM: 'warning',
//     LOW: 'success',
//     show: function (msg, duration = this.SHORT, priority = this.HIGH) {
//         return setTimeout(() => (
//             <Alert key={'1'} variant={priority}>
//                 {msg}
//             </Alert>), duration);
//     },
//
// }

function TextField({ hasError, label, placeholder, onInput, errorMessage, disabled = false}) {
  return (
      <Form.Group controlId="formBasicEmail">
        <Form.Label className={disabled?'disabled-field':''}>{label}</Form.Label>
        <Form.Control disabled={disabled}
            placeholder={placeholder}
            onChange={(event) => onInput(event.target.value)}
        />
        {hasError ? (
            <Form.Text className="text-muted">{errorMessage}</Form.Text>
        ) : null}
      </Form.Group>
  );
}
