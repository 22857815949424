import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import GetStarted from "../pages/GetStarted";
import Dashboard from "../pages/Dashboard";
import AzureAccount from "../pages/Accounts/AzureAccount";
import AddAzureAccount from "../pages/Accounts/AddAzureAccount";
import AddGcpAccount from "../pages/Accounts/AddGcpAccount";

import ConnectAWSAccount from "../pages/Accounts/aws/ConnectAWSAccount";
import ListAWSAccount from "../pages/Accounts/aws/ListAWSAccount";
import DashboardChart from "../pages/Charts/DashboardChart";
import Settings from "../pages/Settings/Settings";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={GetStarted} />
      <Route path="/register" component={GetStarted} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/dashboard/gcp/add" component={AddGcpAccount} isPrivate />
      <Route path="/dashboard/azure/add" component={AddAzureAccount} isPrivate />
      <Route path="/dashboard/aws/connect" component={ConnectAWSAccount} isPrivate />
      <Route path="/dashboard/aws/list" component={ListAWSAccount} isPrivate />
      <Route path="/dashboard/charts" component={DashboardChart} isPrivate />
      <Route path="/dashboard/settings" component={Settings} isPrivate />
      <Route component={GetStarted} />
    </Switch>
  );
}
