import React, { useState, useEffect } from "react";
import axios from "../../connection/axios";

import Gcp_logo from "../../images/GCP_logo.svg";

import { ReactComponent as GCP_LOGO } from "../../images/GCP_logo.svg";
import ConnectionHeader from "../../components/ConnectionHeader";
import "../../components/styles.css";
import QuestionMark from "../../images/questionmark.png";
import { useSelector } from "react-redux";
import { Table, ListGroup, Card, Button, Form } from "react-bootstrap";
import BlueButton from "../../components/BlueButton";
const { REACT_APP_GCP_CONNECTION, REACT_APP_GCP_ADD_ACCOUNT } = process.env;
export default function AddGcpAccount() {
  const [displayName, setDisplayName] = useState("");
  const [displayNameError, setDisplayNameError] = useState({
    status: false,
    msg: "",
  });
  const [tableId, setTableId] = useState("");
  const [tableIdError, setTableIdError] = useState({ status: false, msg: "" });
  const [gcpAccounts, setGcpAccounts] = useState([]);
  const [billingAccounts, setBillingAccounts] = useState([]);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const [canelButton, setCancelButton] = useState(false);
  const userData = useSelector((state) => ({
    id: state.userDetailsReducer.id,
  }));
  function ToggleAccount() {
    setShow(!show);
    setButtonShow(!buttonShow);
    setCancelButton(!canelButton);
  }

  function ValidationTextInput() {
    var noErrors = true;
    if (!displayName.trim()) {
      setDisplayNameError({
        status: true,
        msg: "Please enter Connection Display Name",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.\-_\s]{3,25}$/.test(displayName.trim())) {
      setDisplayNameError({
        status: true,
        msg: "Please enter the valid Connection Display Name",
      });
      noErrors = false;
    }
    if (!tableId.trim()) {
      setTableIdError({
        status: true,
        msg: "Please enter Connection Display Name",
      });
      noErrors = false;
    } else if (!/^[a-zA-Z0-9.\-_\s]{3,25}$/.test(tableId.trim())) {
      setTableIdError({
        status: true,
        msg: "Please enter the valid Connection Display Name",
      });
      noErrors = false;
    }
  }

  function connectGcpDirectory() {
    if (ValidationTextInput()) {
      setConnectionLoading(true);
      axios
        .post(REACT_APP_GCP_CONNECTION, {
          customerId: "8dfb9cda-ba52-4b8b-b679-55048e42cd2a",
          tableId: tableId.trim(),
          displayName: displayName.trim(),
        })
        .then((response) => response.data)
        .then((responseJson) => {
          if ("statusCode" in responseJson) {
            if (responseJson.statusCode == "200") {
              //   onConnectionSuccess()
              //  setGcpAddAccounts(responseJson);
              //   Toast.show('Google account added successfully', Toast.LONG);
              alert("Google account added successfully");
              //  getGCPAccounts();
            } else if (responseJson && responseJson.errorMessage) {
              alert(responseJson.errorMessage);
              //   Toast.show(responseJson.errorMessage, Toast.LONG);
            } else {
              alert("Something went wrong");
            }
            //  setLoading(false);
          }
        })
        .catch()
        .finally(() => setConnectionLoading(false));
    }
  }

  function getGCPAccounts() {
    setConnectionLoading(true);
    axios
      .get(
        REACT_APP_GCP_ADD_ACCOUNT +
          "getGCPAccounts?customerId=8dfb9cda-ba52-4b8b-b679-55048e42cd2a"
      )
      .then((response) => response.data)
      .then((responseJson) => {
        if (responseJson.statusCode == 200) {
          if (responseJson.data.organizations.length > 0) {
            setGcpAccounts(responseJson.data.organizations);
          } else {
            alert("Something went wrong");
          }
        }
        setConnectionLoading(true);
      })
      .finally(() => setConnectionLoading(false))
      .catch((error) => {
        console.error(error);
      });
  }
  function TextField({ hasError, label, placeholder, onInput, errorMessage }) {
    return (
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          placeholder={placeholder}
          onChange={(event) => onInput(event.target.value)}
        />
        {hasError ? (
          <Form.Text className="text-muted">{errorMessage}</Form.Text>
        ) : null}
      </Form.Group>
    );
  }
  useEffect(() => {
    getGCPAccounts();
  }, []);

  return (
    <div>
      <ConnectionHeader
        Icon={GCP_LOGO}
        onConnect={ToggleAccount}
        buttonHidden={show}
        connectionTitle={show ? "Connect GCP Account" : "Manage Account"}
      />

      <div>
        {show ? (
          <div
            style={{
              borderRadius: 5,
              padding: 10,
              borderWidth: 2,
              paddingTop: 10,
              borderColor: "#E8E8E8",
              borderStyle: "solid",
              backgroundColor: "#FFF",
              paddingLeft: 10,
            }}
          >
            <div
              style={{
                alignItems: "center",
                paddingLeft: 600,
                paddingRight: 600,
              }}
            >
              <h3>Account</h3>
              <hr style={{ borderColor: "skyblue", width: "105%" }}></hr>

              <div>
                <br />
                <div style={{ display: "flex", flex: 1, marginLeft: 10 }}>
                  <Form style={{ flex: 1 }}>
                    {/* <TextField label={'Role ARN *'} hasError={roleARNError.status}
                                       errorMessage={roleARNError.msg} onInput={setRoleArn}
                                       placeholder={'Enter Role ARN'} /> */}
                    <TextField
                      label={"Display Name *"}
                      hasError={displayNameError.status}
                      errorMessage={displayNameError.msg}
                      onInput={setDisplayName}
                      placeholder={"Enter Connection Display Name"}
                    />
                    <TextField
                      label={"Table ID *"}
                      hasError={tableIdError.status}
                      errorMessage={tableIdError.msg}
                      onInput={setTableId}
                      placeholder={"Enter Table ID"}
                    />
                    <BlueButton
                      onClick={connectGcpDirectory}
                      name=" Connect"
                    ></BlueButton>
                    <br />{" "}
                    <BlueButton
                      name="Cancel"
                      onClick={ToggleAccount}
                    ></BlueButton>
                  </Form>
                </div>
                {/* <label style={{size:13}}>Display Name</label>
                    <input type='text' placeholder="Enter your Display Name" size="40" onChange={({target: {value}})=>setDisplayName(value)} style={{fontSize:16,height:30}} />
                </div>
                <br/>
                <div>
                    <label style={{size:13}}>Table ID</label>
                    <input type='text' placeholder="Enter your Table ID" size="40" onChange={({target: {value}})=>setTableId(value)}  style={{fontSize:16,height:30}}/>
                </div>
                <br/>
                <div style={{display:'flex',paddingLeft:70}}>
               
                            <BlueButton name="Connect" onClick={connectGcpDirectory}></BlueButton>  */}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              borderRadius: 5,
              padding: 10,
              borderWidth: 2,
              paddingTop: 10,
              borderColor: "#E8E8E8",
              borderStyle: "solid",
              backgroundColor: "#FFF",
              paddingLeft: 10,
            }}
          >
            {gcpAccounts.map((item, index) => (
              <div>
                <ListGroup>
                  <ListGroup.Item>
                    Organization Name: {item.organizationName}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Organization ID: {item.organizationId}
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <div>
                  {item.billingAccounts?.map((item) => (
                    <Table
                      striped
                      bordered
                      hover
                      size="sm"
                      style={{ borderColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th>Billing Name </th>
                          <th>Billing ID</th>
                          <th>Display Name</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{item.billingAccountName}</td>
                          <td>{item.billingAccountId}</td>
                          <td>{item.displayName}</td>
                          <td>{item.status}</td>
                        </tr>
                        <h4>Projects</h4>
                        <Table
                          striped
                          bordered
                          hover
                          size="sm"
                          style={{ borderColor: "white" }}
                        >
                          <thead>
                            <tr>
                              <th>Project ID </th>
                            </tr>
                          </thead>
                          {item.projects?.map((item) => (
                            <tbody>
                              <tr>
                                <td>{item.id}</td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      </tbody>
                    </Table>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
