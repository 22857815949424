import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import Azure from "../pages/Accounts/AddAzureAccount";
import SideBarList from "../components/SideBarList";
import ConnectAWSAccount from "./Accounts/aws/ConnectAWSAccount";
import ListAWSAccount from "./Accounts/aws/ListAWSAccount";
import ConnectGCPAccount from "../pages/GCP/ConnectGCPAccount";
import ListGCPAccount from "../pages/GCP/ListGCPAccount";
import DashboardChart from "../pages/Charts/DashboardChart";
import Settings from "../pages/Settings/Settings";

import Header from "../components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RoutesList from "../routes/RoutesList";
import AWSAccount from "./Accounts/AWSAccount";

const routes = [
  {
    path: "/dashboard/charts",
    exact: true,
    sidebar: () => <SideBarList />,
    main: () => <DashboardChart />,
  },
  {
    path: "/dashboard/azure/add",
    sidebar: () => <SideBarList />,
    main: () => <Azure />,
  },
  {
    path: RoutesList.AWS_ACCOUNT,
    sidebar: () => <SideBarList />,
    main: () => <AWSAccount />,
  },
  // {
  //   path: "/dashboard/aws/connect",
  //   sidebar: () => <SideBarList />,
  //   main: () => <ConnectAWSAccount />,
  // },
  // {
  //   path: "/dashboard/aws/list",
  //   sidebar: () => <SideBarList />,
  //   main: () => <ListAWSAccount />,
  // },
  {
    path: "/dashboard/gcp/connect",
    // sidebar: () => <SideBarList />,
    main: () => <ConnectGCPAccount />,
  },
  {
    path: "/dashboard/gcp/list",
    // sidebar: () => <SideBarList />,
    main: () => <ListGCPAccount />,
  },
  {
    path: "/dashboard/settings",
    // sidebar: () => <SideBarList />,
    main: () => <Settings />,
  },
];

export default function Dashboard() {
  const userData = useSelector((state) => ({
    name: state.userDetailsReducer.name,
    email: state.userDetailsReducer.email,
    id: state.userDetailsReducer.id,
  }));

  useEffect(() => {
    const { REACT_APP_NAME, REACT_APP_REDIRECT_URL } = process.env;
    console.log("log log == ", REACT_APP_REDIRECT_URL);
  }, []);
  return (
    <Router>
      <Container fluid>
        <Header />
        <Row>
          <Col
            md={4}
            style={{
              borderRadius: 5,
              padding: 5,
              borderWidth: 2,
              borderColor: "#E8E8E8",
              borderStyle: "solid",
              backgroundColor: "#FFF",
            }}
          >
            <SideBarList />
          </Col>
          <Col
            md={8}
            style={{
              borderRadius: 5,
              padding: 5,
              borderWidth: 2,
              borderColor: "#E8E8E8",
              borderStyle: "solid",
              backgroundColor: "#FFF",
            }}
          >
            <Switch>
              {routes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </Col>
        </Row>
      </Container>
    </Router>
  );
}
