import React, { useState ,useEffect} from "react";
import {useSelector} from 'react-redux';
import axios from '../../connection/axios';
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import ListGroup from 'react-bootstrap/ListGroup';
const {REACT_APP_GCP_ADD_ACCOUNT} =process.env;
export default function ListGCPAccount() {
  const [gcpAccounts, setGcpAccounts] = useState([]);
  const [connectionLoading, setConnectionLoading] = useState(false);
  const { customerId } = useSelector((state) => ({
    customerId: state.userDetailsReducer.id,
  }));
  function getGCPAccounts() {
    setConnectionLoading(true);
    axios
      .get(REACT_APP_GCP_ADD_ACCOUNT+'getGCPAccounts',{customerId})
      .then((response) => response.data)
      .then((responseJson) => {
        if (responseJson.statusCode == 200) {
          if (responseJson.data.organizations.length > 0) {
            setGcpAccounts(responseJson.data.organizations);
          }  else {
            alert('Something went wrong');
          }
        }
        setConnectionLoading(true);
      })
      .finally(() => setConnectionLoading(false))
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getGCPAccounts();
  }, []);
  return <Card>
    <Card.Header> <b> GCP List Account</b></Card.Header>
    <Card.Body>
    <div  style={{borderRadius: 5, padding: 10, borderWidth:2,paddingTop:10,
              borderColor: '#E8E8E8', borderStyle: 'solid', backgroundColor: '#FFF',paddingLeft:10}}>
                 {gcpAccounts.map((item, index) => (<div>
                    <ListGroup>
                <ListGroup.Item>Organization Name: {item.organizationName}</ListGroup.Item>
                <ListGroup.Item>Organization ID: {item.organizationId}</ListGroup.Item>
              </ListGroup>
          <br/>
        <div> 
          {item.billingAccounts?.map((item)=>(
          <Table striped bordered hover size="sm" style={{borderColor:'white'}}>
  <thead>
    <tr>
      <th>Billing Name </th>
      <th>Billing ID</th>
      <th>Display Name</th>
      <th>Status</th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>{item.billingAccountName}</td>
        <td>{item.billingAccountId}</td>
        <td>{item.displayName}</td>
        <td>{item.status}</td>
      </tr>
      <h4>Projects</h4>
      <Table striped bordered hover size="sm" style={{borderColor:'white'}}>
  <thead>
    <tr>
      <th>Project ID </th>
    </tr>
  </thead>
  {item.projects?.map((item)=>(
  <tbody>
      <tr>
        <td>{item.id}</td>
      </tr>
  </tbody>
   ))}
</Table>
  </tbody>
</Table>
 ))}
        </div>
      </div>
    ))}
              </div>
    </Card.Body>
  </Card>
}
