import { combineReducers } from "redux";
import tokenReducer from "./tokenReducer";
import userDetailsReducer from "./userDetailsReducer";

const rootReducer = (state, action) => {
  if (action.type === "SIGN_OUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

const appReducer = combineReducers({
  tokenReducer,
  userDetailsReducer,
});
