import React, { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import { useSelector } from "react-redux";
import axios from "../../connection/axios";
import { Chart } from "react-google-charts";

const {
  REACT_APP_ALL_HOME_SUMMARY
} = process.env;
export default function DashboardChart() {
  const [tenantsList, setTenantsList] = useState([]);
  const { customerId } = useSelector((state) => ({
    customerId: state.userDetailsReducer.id,
  }));
  // console.log("rahat customer id == ", customerId);
  // console.log("REACT_APP_ALL_HOME_SUMMARY == ", REACT_APP_ALL_HOME_SUMMARY);
  async function getAccountList() {
    // setLoading(true);
    await axios
      .get(REACT_APP_ALL_HOME_SUMMARY, {
        params: { customerId },
      })
      .then((response) => response.data)
      .then((response) => {
        // console.log('response-------------', response);
        if (response.statusCode === 200) {
          let ThisMonthSpendObj=0;
          let LastMonthSpendObj=0;
          let SpendByAccountArray = [['Accounts', 'Cost']];
          let AllCloudSpendingArray = [['AllCloud', 'Cost']];
          let TopFiveServicesArray = [['Services', 'Cost']];
          let TopFiveLocationsArray = [['Locations', 'Cost']];
          
             
          if(response && response.data && response.data.charts && response.data.charts.ThisMonthSpend &&
            response.data.charts.ThisMonthSpend.totalCost
            && Number(response.data.charts.ThisMonthSpend.totalCost) > 0) {
              console.log('response.data.charts.ThisMonthSpend----------', response.data.charts.ThisMonthSpend.totalCost)
              ThisMonthSpendObj= Number( response.data.charts.ThisMonthSpend.totalCost);
            }

            if(response && response.data && response.data.charts && response.data.charts.LastMonthSpend &&
              response.data.charts.LastMonthSpend.totalCost
              && Number(response.data.charts.LastMonthSpend.totalCost) > 0) {
                console.log('response.data.charts.LastMonthSpend----------', response.data.charts.LastMonthSpend.totalCost)
                LastMonthSpendObj= Number( response.data.charts.LastMonthSpend.totalCost);
              }

          if(response && response.data && response.data.charts && response.data.charts.SpendByAccounts &&
            response.data.charts.SpendByAccounts["1M"] && response.data.charts.SpendByAccounts["1M"].dataSet
            && Number(response.data.charts.SpendByAccounts["1M"].dataSet.length) > 0) {
     for(let SpendByAccountObj of response.data.charts.SpendByAccounts["1M"].dataSet ) {
  SpendByAccountArray.push([SpendByAccountObj.label, Number(SpendByAccountObj.value) ])
}
            }

            if(response && response.data && response.data.charts && response.data.charts.AllCloudSpending &&
              response.data.charts.AllCloudSpending["1M"] && response.data.charts.AllCloudSpending["1M"].dataSet
              && Number(response.data.charts.AllCloudSpending["1M"].dataSet.length) > 0) {
       for(let AllCloudSpendingObj of response.data.charts.AllCloudSpending["1M"].dataSet ) {
        AllCloudSpendingArray.push([AllCloudSpendingObj.label, Number(AllCloudSpendingObj.value) ])
  }
              }

              if(response && response.data && response.data.charts && response.data.charts.TopFiveServices &&
                response.data.charts.TopFiveServices["1M"] && response.data.charts.TopFiveServices["1M"].dataSet
                && Number(response.data.charts.TopFiveServices["1M"].dataSet.length) > 0) {
         for(let TopFiveServicesObj of response.data.charts.TopFiveServices["1M"].dataSet ) {
          TopFiveServicesArray.push([TopFiveServicesObj.label, Number(TopFiveServicesObj.value) ])
    }
                }

                if(response && response.data && response.data.charts && response.data.charts.TopFiveLocations &&
                  response.data.charts.TopFiveLocations["1M"] && response.data.charts.TopFiveLocations["1M"].dataSet
                  && Number(response.data.charts.TopFiveLocations["1M"].dataSet.length) > 0) {
           for(let TopFiveLocationsObj of response.data.charts.TopFiveLocations["1M"].dataSet ) {
            TopFiveLocationsArray.push([TopFiveLocationsObj.label, Number(TopFiveLocationsObj.value) ])
      }
                  }
  
              let ObjAll={ThisMonthSpendObj:ThisMonthSpendObj,
                LastMonthSpendObj:LastMonthSpendObj,
                SpendByAccountArray:SpendByAccountArray,
                AllCloudSpendingArray:AllCloudSpendingArray,
                TopFiveServicesArray:TopFiveServicesArray,
                TopFiveLocationsArray:TopFiveLocationsArray};
          setTenantsList(ObjAll);
        } else {
        }
        // console.log(response);
      })
    // .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAccountList();
  }, [customerId]);

  return (
    <div>
      <CardDeck>
        <Card border="primary" style={{ width: '18rem' }}>
          <Card.Header>This Month Spend</Card.Header>
          <Card.Body>
            
            <Card.Title>
               {Number(tenantsList.ThisMonthSpendObj) > 0 ? `$` + tenantsList.ThisMonthSpendObj : <span>"No cost found"</span>}

              </Card.Title>

            {/* <Card.Text>
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </Card.Text> */}
          </Card.Body>
        </Card>
        <Card border="primary" style={{ width: '18rem' }}>
          <Card.Header>Last Month Spend</Card.Header>
          <Card.Body>
            <Card.Title> 
            {Number(tenantsList.LastMonthSpendObj) > 0 ? `$` + tenantsList.LastMonthSpendObj : <span>"No cost found"</span>}

              {/* {tenantsList && tenantsList.charts && tenantsList.charts.LastMonthSpend && 
              tenantsList.charts.LastMonthSpend.totalCost && Number(tenantsList.charts.LastMonthSpend.totalCost) > 0 ? `$` + tenantsList.charts.LastMonthSpend.totalCost : <span>"No cost found"</span>} */}
              </Card.Title>

            {/* <Card.Text>
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </Card.Text> */}
          </Card.Body>
        </Card>
        {/* <Card border="primary" style={{ width: '18rem' }}>
    <Card.Header>Top 5 Services</Card.Header>
    <Card.Body>
      <Card.Title>$ 120</Card.Title>
   </Card.Body>
  </Card>
  <Card border="primary" style={{ width: '18rem' }}>
    <Card.Header>Top 5 Locations</Card.Header>
    <Card.Body>
      <Card.Title>$ 500</Card.Title>
    </Card.Body>
  </Card> */}

      </CardDeck>
      <br></br>

      <CardDeck>
        <Card>
          <Card.Header>Accounts</Card.Header>
          <Card.Title>1 Month</Card.Title>
          <Card.Body>
    
<Chart
  width={'500px'}
  height={'300px'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={tenantsList.SpendByAccountArray}
  options={{
    title: 'Spend By Accounts',
  }}
  rootProps={{ 'data-testid': '1' }}
/>
{/* { JSON.stringify(tenantsList.SpendByAccountArray) } */}
            {/* {tenantsList && tenantsList.charts && tenantsList.charts.SpendByAccounts &&
              tenantsList.charts.SpendByAccounts["1M"] && tenantsList.charts.SpendByAccounts["1M"].dataSet
              && Number(tenantsList.charts.SpendByAccounts["1M"].dataSet.length) > 0 ? tenantsList.charts.SpendByAccounts["1M"].dataSet.map((item, index) => (
                <Card.Text>
                  {item.label} : {item.value}
                </Card.Text>
              )) : <span>"No cost found"</span>} */}
          </Card.Body>
        </Card>

        {/* <Card>
          <Card.Header>All Cloud Spending</Card.Header>
          <Card.Title>1 Month</Card.Title>
          <Card.Body>
            {tenantsList && tenantsList.charts && tenantsList.charts.AllCloudSpending &&
              tenantsList.charts.AllCloudSpending["1M"] && tenantsList.charts.AllCloudSpending["1M"].dataSet
              && Number(tenantsList.charts.AllCloudSpending["1M"].dataSet.length) > 0 ? tenantsList.charts.AllCloudSpending["1M"].dataSet.map((item, index) => (
                <Card.Text>
                  {item.label} : {item.value}
                </Card.Text>
              )) : <span>"No cost found"</span>}
          </Card.Body>
        </Card> */}

        <Card>
          <Card.Header>Top 5 Services</Card.Header>
          <Card.Title>1 Month</Card.Title>
          <Card.Body>
            {/* {tenantsList && tenantsList.charts && tenantsList.charts.AllCloudSpending &&
              tenantsList.charts.AllCloudSpending["1M"] && tenantsList.charts.AllCloudSpending["1M"].dataSet
              && Number(tenantsList.charts.AllCloudSpending["1M"].dataSet.length) > 0 ? tenantsList.charts.AllCloudSpending["1M"].dataSet.map((item, index) => (
                <Card.Text>
                  {item.label} : {item.value}
                </Card.Text>
              )) : <span>"No cost found"</span>} */}
              
<Chart
  width={'500px'}
  height={'300px'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={tenantsList.AllCloudSpendingArray}
  options={{
    title: 'Top 5 Services',
  }}
  rootProps={{ 'data-testid': '1' }}
/>
{/* { JSON.stringify(tenantsList.AllCloudSpendingArray) } */}
          </Card.Body>
        </Card>

        {/* <Card>
  <Card.Header>Services</Card.Header>
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This is a wider card with supporting text below as a natural lead-in to
        additional content. This content is a little bit longer.
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
  <Card.Header>Locations</Card.Header>
    <Card.Body>
      <Card.Title>Card title</Card.Title>
      <Card.Text>
        This is a wider card with supporting text below as a natural lead-in to
        additional content. This content is a little bit longer.
      </Card.Text>
    </Card.Body>
  </Card> */}

      </CardDeck>
      <br></br>

<CardDeck>
  <Card>
    <Card.Header>Spend By Services</Card.Header>
    <Card.Title>1 Month</Card.Title>
    <Card.Body>

<Chart
width={'500px'}
height={'300px'}
chartType="PieChart"
loader={<div>Loading Chart</div>}
data={tenantsList.TopFiveServicesArray}
options={{
title: 'Spend By Services',
}}
rootProps={{ 'data-testid': '1' }}
/>
    </Card.Body>
  </Card>

  <Card>
    <Card.Header>Spend By Locations</Card.Header>
    <Card.Title>1 Month</Card.Title>
    <Card.Body>
        
<Chart
width={'500px'}
height={'300px'}
chartType="PieChart"
loader={<div>Loading Chart</div>}
data={tenantsList.TopFiveLocationsArray}
options={{
title: 'Spend By Locations',
}}
rootProps={{ 'data-testid': '1' }}
/>
    </Card.Body>
  </Card>

</CardDeck>


<CardDeck>
  <Card>
    <Card.Header>Spend By Services</Card.Header>
    <Card.Title>1 Month</Card.Title>
    <Card.Body>

<Chart
  width={'500px'}
  height={'300px'}
  chartType="BarChart"
  loader={<div>Loading Chart</div>}

data={tenantsList.TopFiveServicesArray}
options={{
title: 'Spend By Services',
chartArea: { width: '50%' },
    hAxis: {
      title: 'Spend By Services',
      minValue: 0,
    },
    vAxis: {
      title: 'City',
    },
}}
rootProps={{ 'data-testid': '1' }}
/>
    </Card.Body>
  </Card>

  <Card>
    <Card.Header>Spend By Locations</Card.Header>
    <Card.Title>1 Month</Card.Title>
    <Card.Body>
        
<Chart
  width={'500px'}
  height={'300px'}
  chartType="BarChart"
  loader={<div>Loading Chart</div>}

data={tenantsList.TopFiveLocationsArray}
options={{
title: 'Spend By Locations',
chartArea: { width: '50%' },
    hAxis: {
      title: 'Spend By Locations',
      minValue: 0,
    },
    vAxis: {
      title: 'City',
    },
}}
rootProps={{ 'data-testid': '1' }}
/>
    </Card.Body>
  </Card>

</CardDeck>
    </div>
  );
}
