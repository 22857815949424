import React, { useState ,useEffect} from "react";
import {useSelector} from 'react-redux';
import axios from '../../connection/axios';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import BlueButton from "../../components/BlueButton";
const {REACT_APP_GCP_CONNECTION} = process.env;
export default function ConnectGCPAccount() {
  const [connectionLoading, setConnectionLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [displayNameError, setDisplayNameError] = useState({status: false, msg: ''});
  const [tableId, setTableId] = useState("");
  const [tableIdError, setTableIdError] = useState({status: false, msg: ''});
  const { customerId } = useSelector((state) => ({
    customerId: state.userDetailsReducer.id,
  }));
  function ValidationTextInput() {
    var noErrors = true;
    if (!displayName.trim()) {
        setDisplayNameError({ status: true, msg: 'Please enter Display Name' });
        noErrors = false;
    } else if(!/^[a-zA-Z0-9.\-_\s]{3,25}$/.test(displayName.trim())) {
        setDisplayNameError({status: true, msg: 'Please enter the valid Connection Display Name'});
        noErrors = false;
    }
    if (!tableId.trim()) {
        setTableIdError({ status: true, msg: 'Please enter Table ID' });
        noErrors = false;
    } return noErrors;
  } 
  function connectGcpDirectory() {
    if (ValidationTextInput()) {
      setConnectionLoading(true);
      axios
    .post(REACT_APP_GCP_CONNECTION, {
      customerId,
      tableId: tableId.trim(),
      displayName: displayName.trim(),
    })
    .then((response) => response.data)
    .then((responseJson) => {
      if ('statusCode' in responseJson) {
        if (responseJson.statusCode == '200') {
        alert('Google account added successfully');
        } else if (responseJson && responseJson.errorMessage) {
            alert(responseJson.errorMessage);
        } else {
          alert('Something went wrong');
        }
      }
    })
    .catch()
    .finally(() => setConnectionLoading(false));

  }
}

  return (
    <Card>
      <Card.Header>
        <b>Connect GCP Account</b>
      </Card.Header>
      <Card.Body>
      <Form >
      <TextField label={'Display Name *'} hasError={displayNameError.status}
                                       errorMessage={displayNameError.msg} onInput={setDisplayName}
                                       placeholder={'Enter Display Name'} />
      <TextField label={'Table ID *'} hasError={tableIdError.status}
                                       errorMessage={tableIdError.msg} onInput={setTableId}
                                       placeholder={'Enter Table ID'} />
              <div style={{display:'block'}}><BlueButton onClick={connectGcpDirectory} name=" Connect" /></div> 
              </Form>
      </Card.Body>
    </Card>
  );
}

function TextField({hasError, label, placeholder, onInput, errorMessage}){
  return (
      <Form.Group controlId="formBasicEmail">
          <Form.Label>{label}</Form.Label>
          <Form.Control placeholder={placeholder} onChange={(event)=>onInput(event.target.value)} />
          {hasError?<Form.Text className="text-muted">{errorMessage}</Form.Text>:null}
      </Form.Group>
  )
}