import React, { useState, useEffect } from "react";
import Azure_logo from "../../images/Azure_logo.svg";
import Azure_key from "../../images/key.svg";
import ProgressIndicator from "../../components/ProgressIndicator";
import QuestionMark from "../../images/questionmark.png";
import Refresh from "../../images/refresh.svg";
import axios from "../../connection/axios";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import {
  Button,
  Card,
  ListGroup,
  Jumbotron,
  Collapse,
  Toast,
  Table,
  Alert,
} from "react-bootstrap";
const {
  REACT_APP_AZURE_CONNECTION,
  REACT_APP_LIVE_AZURE_SUBSCRIPTION,
  REACT_APP_ENABLE_DISABLE_SUBSCRIPTION,
  REACT_APP_ADD_AND_VERIFY,
} = process.env;

export default function AddAzureAccount() {
  const [deviceCode, setdeviceCode] = useState([]);
  const [accountListResponse, setAccountListResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const userData = useSelector((state) => ({
    id: state.userDetailsReducer.id,
  }));

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var code = url.searchParams.get("code");
    if(code!=null){
        connectionCode(code);
    } 
    accountListApi();
  }, [userData.id]);

  return (
    <div>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={Azure_logo}
              alt="Azure logo"
              style={{
                width: 30,
                height: 30,
                marginRight: 12,
                borderStyle: "solid",
                borderWidth: 1,
                padding: 4,
                borderRadius: 4,
              }}
            />
            <h2>New Accounts</h2>
            {loading && <ProgressIndicator />}
          </div>

          <Button
            variant="primary"
            onClick={deviceFlowApi}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            Connect account Azure
          </Button>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ color: "gray" }}>
            Pre-requisites to connect Azure account
          </span>

          <img
            src={QuestionMark}
            alt="QuestionMark"
            style={{ width: 20, height: 20, marginLeft: 12 }}
          />
        </div>

        <Collapse in={open}>
          <div id="example-collapse-text">
            <h4>Use this unique code {deviceCode}</h4>
          </div>
        </Collapse>

        {accountListResponse && accountListResponse.length ? (
          accountListResponse.map((item, index) => (
            <Card style={{ marginTop: 16 }}>
              <div
                style={{
                  borderRadius: 5,
                  padding: 8,
                }}
              >
                <ListGroup>
                  <ListGroup.Item>
                    Tenant Name: {item.tenantName}
                  </ListGroup.Item>
                  <ListGroup.Item>Tenant ID: {item.tenantId}</ListGroup.Item>
                </ListGroup>

                <div
                  style={{ display: "flex", alignItems: "center", margin: 4 }}
                >
                  <span style={{ color: "gray", marginRight: 8 }}>
                    Last updated yesterday
                  </span>
                  <img
                    onClick={() => addAndVerifySubscription(item.tenantId)}
                    src={Refresh}
                    alt="refresh"
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 12,
                    }}
                  />
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Subscription Name</th>
                      <th>Subscription Id</th>
                      <th>Status</th>
                      <th>Link/Unlink</th>
                    </tr>
                  </thead>

                  {item.subscriptions && item.subscriptions.length ? (
                    item.subscriptions?.map((item) => (
                      <tbody>
                        <tr>
                          <td>{item.subName}</td>
                          <td>{item.subscriptionId}</td>
                          <td>{item.status}</td>
                          {item.status !== "unauthorized" && (
                            <td>
                              <Switch
                                checked={item.isEnabled === true}
                                onChange={() => {
                                  setSwitchValue(
                                    item.isEnabled,
                                    item.subscriptionId
                                  );
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <span>No Subscription found</span>
                  )}
                </Table>
              </div>
            </Card>
          ))
        ) : (
          <span>No Accounts found</span>
        )}
      </div>
    </div>
  );

  function LinkUnlinkModel() {
    return (
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Modal title
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function setSwitchValue(isEnabled, id) {
    {
      isEnabled === true
        ? enableAndDisableSubscriptions(id, false)
        : enableAndDisableSubscriptions(id, true);
    }
  }

  function enableAndDisableSubscriptions(subID, status) {
    setLoading(true);
    axios
      .get(REACT_APP_ENABLE_DISABLE_SUBSCRIPTION, {
        params: {
          customerID: userData.id,
          subscriptionID: subID,
          isEnabled: status,
        },
      })
      .then((response) => response.data)
      .then((responseJson) => {
        if ("statusCode" in responseJson) {
          if (responseJson.statusCode == 200) {
            accountListApi();
          } else {
            // Toast.show(responseJson.msg);
          }
        } else {
          // Toast.show('Something went wrong');
        }

        return responseJson;
      })

      .catch((error) => {
        console.error(error);
      });
  }

  function deviceFlowApi() {
    setLoading(true);
    axios
      .get(
        `https://costunify.azure-api.net/azure-connection-msal/connectAzureAccount?customerID=${userData.id}`
      )
      .then((response) => response.data)
      .then((responseJson) => {
        console.log("new azure connection", responseJson);
        if ("response" in responseJson) {
          window.open(responseJson.response.url, "_self");
        }

        return responseJson;
      })
      .finally(() => setLoading(false))
      .catch((error) => {
        console.error(error);
      });
  }

  function accountListApi() {
    setLoading(true);
    axios
      .get(
        REACT_APP_LIVE_AZURE_SUBSCRIPTION +
          `getSubscriptions?customerID=${userData.id}`
      )
      .then((response) => response.data)
      .then((responseJson) => {
        if ("statusCode" in responseJson) {
          if (responseJson.statusCode == 200) {
            console.log("list response", responseJson);
            setAccountListResponse(responseJson.response.azure);
          } else {
            // Toast.show(responseJson.msg);
          }
        } else {
          //  Toast.show('Something went wrong');
        }
      })
      .finally(() => setLoading(false))

      .catch((error) => {
        console.error(error);
      });
  }

  function addAndVerifySubscription(tenantID) {
    setLoading(true);
    axios
      .get(REACT_APP_ADD_AND_VERIFY, {
        params: {
          customerID: userData.id,
          tenantID: tenantID,
        },
      })
      .then((response) => response.data)
      .then((responseJson) => {
        if ("statusCode" in responseJson) {
          if (responseJson.statusCode == 200) {
            // Toast.show("Your subscription updated successfully");
            accountListApi();
          } else {
            //Toast.show(responseJson.msg);
          }
        } else {
          //Toast.show('Something went wrong');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }
  function connectionCode(code) {
    console.log('connection code',code)
    setLoading(true);
    axios
      .post('https://costunify.azure-api.net/azure-connection-msal/connectTenants', {
        code: code,
        state: userData.id,
      })
      .then((response) => response.data)
      .then((responseJson) => {
        if ("statusCode" in responseJson) {
            if (responseJson.statusCode == 200) {
                // Toast.show("Your subscription updated successfully");
                console.log("User code api response");
              } else {
                //Toast.show(responseJson.msg);
              }
        } else {
          //Toast.show('Something went wrong');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }
}
