import axios from "axios";
const { REACT_APP_BASE_URL, REACT_APP_SUBSCRIPTION_KEY } = process.env;

const Axios = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    "Ocp-Apim-Subscription-Key": REACT_APP_SUBSCRIPTION_KEY,
  },
});
export default Axios;
