import React from "react";
import UserAuth from "../UserAuth";
import { Container } from "../styles/signinStyle";
import backgroundImage from "../images/background.png";
import AppLogo from "../images/app_logo.png";
import { useSelector } from "react-redux";

export default function GetStarted() {
  return (
    <Container>
      <div>
        <div>
          <img src={AppLogo} />
          <h2>CostUnify</h2>
        </div>
        <button onClick={UserAuth().loginAsync}>Sign In/Sign Up</button>
      </div>
      <img alt="get_started_background" src={backgroundImage} width={"100%"} />
    </Container>
  );
}
