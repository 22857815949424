import React from "react";
import styles from './styles.css';

export default function BlueButton({name, onClick}) {
    function beforeClick(e){
        e.preventDefault();
        onClick();
    }
  return (
      <div onClick={onClick} className={"blue-button-back"} >
          <span className={'blue-button-text'}>{name}</span>
      </div>
  );
}
