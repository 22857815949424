import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { SIGNIN_SIGNUP } from "../store/actions/actions";
import { useSelector, useDispatch } from "react-redux";

const check = localStorage.getItem("signed");

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const dispatch = useDispatch();
  const { signed } = useSelector((state) => ({
    signed: state.userDetailsReducer.signed,
  }));
  useEffect(() => {
    const user = localStorage.getItem("userData");
    if (user != null) {
      dispatch({
        type: SIGNIN_SIGNUP,
        payload: JSON.parse(user),
      });
    }
  }, []);

  if (check != null) {
    const { signed } = JSON.parse(check);
    if (isPrivate && !signed) {
      return <Redirect to="/" />;
    }
    /**
     * Redirect user to Main page if he tries to access a non private route
     * (SignIn or SignUp) after being authenticated.
     */
    if (!isPrivate && signed) {
      return <Redirect to="/dashboard" />;
    }
    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return <Route {...rest} component={Component} />;
  } else {
    if (isPrivate && !signed) {
      return <Redirect to="/" />;
    }
    /**
     * Redirect user to Main page if he tries to access a non private route
     * (SignIn or SignUp) after being authenticated.
     */
    if (!isPrivate && signed) {
      return <Redirect to="/dashboard" />;
    }
    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return <Route {...rest} component={Component} />;
  }

  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
}
RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
};
