import { SIGNIN_SIGNUP } from "../actions/actions";

const userState = {
  user_details: [],
  name: "",
  email: "",
  id: "",
  newUser: true,
  accountStatus: false,
  signed: false,
};

function userDetailsReducer(state = userState, { type, payload }) {
  switch (type) {
    case SIGNIN_SIGNUP: {
      localStorage.setItem("userData", JSON.stringify(payload));
      localStorage.setItem("signed", JSON.stringify({ signed: true }));
      return {
        ...state,
        user_details: payload,
        newUser: payload.newUser,
        name: payload.b2c_display_name,
        email: payload.emailId,
        id: payload.customerID,
        accountStatus: payload.accountStatus,
        signed: true,
      };
    }
    case "SIGN_OUT": {
      return {
        ...state,
        signed: false,
      };
    }

    default:
      return state;
  }
}
export default userDetailsReducer;
