import React, {createRef, useEffect, useRef, useState} from "react";
import ConnectAWSAccount from "./aws/ConnectAWSAccount";
import ListAWSAccount from "./aws/ListAWSAccount";
import {Button, Carousel} from "react-bootstrap";

const {
  REACT_APP_AWS_TENANTS,
} = process.env;

export default function AWSAccount() {
  const [nowShowing, setNowShowing] = useState(0);
  const [shouldReload, setShouldReload] = useState(false);
  function onConnectionSuccess(){
    setNowShowing(0);
    setShouldReload(!shouldReload);
  }

  function onConnect(){
    setNowShowing(1)
  }

  return (
      <div>
        <Carousel className={'awsCarousel'} activeIndex={nowShowing}
                  keyboard={false} touch={false} interval={null} fade={true} controls={false} indicators={false}>
          <Carousel.Item>
            <ListAWSAccount onConnect={onConnect} shouldReload={shouldReload}  />
          </Carousel.Item>
          <Carousel.Item>
          <ConnectAWSAccount onConnectionSuccess={onConnectionSuccess} onBack={()=>setNowShowing(0)} />
          </Carousel.Item>
        </Carousel>
        {/*{*/}
        {/*  nowShowing === 'list'?*/}
        {/*      <ListAWSAccount onConnect={onConnect}/>:*/}
        {/*      <ConnectAWSAccount onConnectionSuccess={onConnectionSuccess}/>*/}
        {/*}*/}
      </div>
  )
}
