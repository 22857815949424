import React, { useEffect, useState } from "react";
import AWS_LOGO from "../../../images/AWS_LOGO";
import { ReactComponent as AWS_SUB } from "../../../images/aws_sub.svg";
import ConnectionHeader from "../../../components/ConnectionHeader";
import axios from "../../../connection/axios";
import { Button, Form, Collapse } from "react-bootstrap";
import { useSelector } from "react-redux";
import APP_LOGO from "../../../images/app_logo.png";
import history from "../../../services/history";
import RoutesList from "../../../routes/RoutesList";
import {BrowserRouter, Link} from "react-router-dom";
const {
  REACT_APP_AWS_TENANTS,
} = process.env;

export default function ListAWSAccount(props) {
  const [tenantsList, setTenantsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { customerId } = useSelector((state) => ({
    customerId: state.userDetailsReducer.id,
  }));

  async function getAccountList() {
    setLoading(true);
    await axios
        .get(REACT_APP_AWS_TENANTS, {
          params: { customerId },
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.statusCode === 200) {
            setTenantsList(response.data);
          } else {
          }
          console.log(response);
        })
        .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAccountList();
  }, [customerId, props.shouldReload]);

  function LoadingImage() {
    setInterval(() => {});
    return (
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <img src={APP_LOGO} style={{}} className={"image"} />
          {/*<span>Loading accounts</span>*/}
        </div>
    );
  }

  function onConnect(){
      props.onConnect();
      // return <Link to={RoutesList.AWS_CONNECT} />
      // history.push(RoutesList.AWS_CONNECT);
      // new BrowserRouter().props.forceRefresh();
  }

  return (
      <div style={{ padding: 50 }}>
        <ConnectionHeader
            Icon={AWS_LOGO}
            onConnect={onConnect}
        />
        <div
            style={{
              display: loading ? "flex" : "inherit",
              alignItems: "center",
              justifyContent: "center",
            }}
        >
          {loading ? (
              <LoadingImage />
          ) : tenantsList && tenantsList.length ? (
              tenantsList.map((t, i) => (
                  <Tenant name={t.displayName} organizations={t.organization} key={i} />
              ))
          ) : (
              <span>No accounts found</span>
          )}
        </div>
      </div>
  );
}

function Tenant({ name, organizations }) {
  return (
      <div
          style={{
            borderRadius: 5,
            padding: 20,
            borderWidth: 2,
            borderColor: "#E8E8E8",
            borderStyle: "solid",
            backgroundColor: "#FFF",
          }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ padding: 5, backgroundColor: "#FFF" }}>
            <AWS_SUB />
          </div>
          <span style={{ marginLeft: 10, fontWeight: "bold" }}>{name}</span>
        </div>
        <span style={{ fontSize: 13 }}>
        Last updated yesterday - Update Subscriptions
      </span>
        {(organizations && organizations.length)?organizations.map((o, i) => (
            <Organization
                name={o.Name}
                accounts={o.Account}
                orgUnits={o.OrgUnits}
                key={i}
            />
        )):null}
      </div>
  );
}

function Organization({ name, accounts, orgUnits }) {
  return (
      <div style={{ marginTop: 10 }}>
      <span
          style={{
            fontFamily: "Poppins_SemiBold",
            marginTop: 5,
            marginBottom: 5,
          }}
      >
        {name}
      </span>
        <br />
        <div style={{ marginTop: 5, display: "flex", flex: 1 }}>
          {(accounts && accounts.length)?accounts.map((a, i) => (
              <Account name={a.Name} orgUnits={orgUnits} id={a.Id} key={i}/>
          )):null}
        </div>
      </div>
  );
}

function Account({ color: variant, name, id, accounts = [], orgUnits = [] }) {
  const [isCollapsing, setIsCollapsing] = useState(false);
  function onCollapse() {
    setIsCollapsing(!isCollapsing);
  }
  const shouldShowCollapse =
      accounts?.length > 0 || orgUnits?.length > 0 || null;
  return (
      <div style={{ padding: 5, marginLeft: 25, display: "block" }}>
        <Button
            variant={variant}
            onClick={onCollapse}
            aria-controls="accounts-list"
            aria-expanded={isCollapsing}
        >
          {name} {id ? "(" + id + ")" : null}{" "}
          {shouldShowCollapse ? (isCollapsing ? "+" : "-") : ""}
        </Button>
        <Collapse in={!isCollapsing}>
          <div id="accounts-list">
            {(accounts && accounts.length)?accounts.map((a, i) => (
                <Account
                    color={"success"}
                    name={a.Name}
                    orgUnits={orgUnits}
                    id={a.Id}
                    key={i}
                />
            )):null}
            {(orgUnits && orgUnits.length)?orgUnits.map((ou, i) => (
                <Account
                    color={"info"}
                    name={ou.Name}
                    accounts={ou?.Account}
                    orgUnits={ou.OrgUnits}
                    key={i}
                />
            )):null}
          </div>
        </Collapse>
      </div>
  );
}

// const Toast = {
//     LONG: 4000,
//     SHORT: 2000,
//     HIGH: 'danger',
//     MEDIUM: 'warning',
//     LOW: 'success',
//     show: function (msg, duration = this.SHORT, priority = this.HIGH) {
//         return setTimeout(() => (
//             <Alert key={'1'} variant={priority}>
//                 {msg}
//             </Alert>), duration);
//     },
//
// }
