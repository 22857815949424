import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store/store";

import history from "./services/history";
import Routes from "./routes/index";

function App() {
  return (
    <Provider store={store}>
      <Router forceRefresh={true}>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
