import React from "react";
import BlueButton from "./BlueButton";

export default function ConnectionHeader({Icon, onConnect,connectionTitle="Manage Accounts",buttonHidden=false}) {
  return (
      <div className={'connection-header-container'}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              {Icon?
                  <div className={'icon-background'}>
                      <Icon/>
                  </div>
                  :null
              }
              <span style={{ marginLeft: 10,fontFamily:'Poppins_SemiBold' ,fontSize:20 }}>{connectionTitle}</span>
          </div>
         { !buttonHidden && <BlueButton onClick={onConnect} name={'Connect Account'}  />}
      </div>
  );
}
