import { ADB2C_TOKENS } from "../actions/actions";

const tokenState = {
  id_token: "",
};

function tokenReducer(state = tokenState, { type, payload }) {
  switch (type) {
    case ADB2C_TOKENS: {
      return {
        ...state,
        id_token: payload,
      };
    }

    default:
      return state;
  }
}
export default tokenReducer;
