const RoutesList = {
    ROOT: "/",
    REGISTER:      "/register",
    DASHBOARD:     "/dashboard",
    GCP_HOME:      "/dashboard/gcp",
    GCP_CONNECT:   "/dashboard/gcp/add",
    GCP_LIST:      "/dashboard/gcp/list",
    AWS_HOME:      "/dashboard/aws",
    AWS_ACCOUNT:   "/dashboard/aws/accounts",
    AWS_CONNECT:   "/dashboard/aws/connect",
    AWS_LIST:      "/dashboard/aws/list",
    AZURE_HOME:    "/dashboard/azure",
    AZURE_CONNECT: "/dashboard/azure/connect",
    AZURE_LIST:    "/dashboard/azure/list",
    SETTINGS:      "/dashboard/settings",
    CHARTS:        "/dashboard/charts",
};

export default RoutesList;
