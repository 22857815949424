import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RoutesList from "../routes/RoutesList";
import Nav from "react-bootstrap/Nav";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function SideBarList() {
  return (
    <ListGroup>
      <ListGroup.Item action>
        <Link to="/dashboard/charts">Dashboard</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        Clouds
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Azure
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Nav className="flex-column">
                  <Nav.Link as={Link} to="/dashboard/azure/add">
                    Accounts
                  </Nav.Link>
                  <Nav.Link as={Link} to="/dashboard/azure/add">
                    Subscriptions
                  </Nav.Link>
                  <Nav.Link as={Link} to="/dashboard/azure/add">
                    Report
                  </Nav.Link>
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                AWS
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <Nav className="flex-column">
                  <Nav.Link as={Link} to={RoutesList.AWS_ACCOUNT}>
                    Accounts
                  </Nav.Link>
                  {/*<Nav.Link as={Link} to="/dashboard/aws/connect">*/}
                  {/*  Add Account*/}
                  {/*</Nav.Link>*/}
                  {/*<Nav.Link as={Link} to="/dashboard/aws/list">*/}
                  {/*  List AWS Account*/}
                  {/*</Nav.Link>*/}
                  {/* <Nav.Link>
                        <Link to="/ConnectAWSAccount">ConnectAWSAccount</Link>
                      </Nav.Link>
                      <Nav.Link eventKey="link-1">
                        <Link to="/ListAWSAccount">ListAWSAccount</Link>
                      </Nav.Link> */}
                  <Nav.Link eventKey="link-2">Report</Nav.Link>
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                GCP
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <Nav className="flex-column">
                  <Nav.Link as={Link} to="/dashboard/gcp/connect">
                    Add Account
                  </Nav.Link>
                  <Nav.Link as={Link} to="/dashboard/gcp/list">
                    List GCP Account
                  </Nav.Link>
                  <Nav.Link eventKey="link-2">Report</Nav.Link>
                </Nav>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </ListGroup.Item>
      <ListGroup.Item action>
      <Nav.Link as={Link} to="/dashboard/settings">
      Settings
                  </Nav.Link>
      </ListGroup.Item>

      <ListGroup.Item >Help</ListGroup.Item>
    </ListGroup>
  );
}
