import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import UserAuth from "../UserAuth";
import AppLogo from "../images/app_logo.png";
export default function Header() {
  return (
    <Row>
      <Col md={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Navbar bg="primary" variant="dark">
          <Navbar.Brand> CostUnify</Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Button onClick={UserAuth().logoutAsync} variant="outline-light">
              Log out
            </Button>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  );
}
